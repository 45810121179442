export enum MSV {
  FISCOD_ENABLED = 'FCD',
  LANGUAGE_SWITCH = 'LAN',
  RECOMMENDED = 'REC',
  GIFTCARD = 'GFC',
  FINANCING = 'FNC',
  EVENTS_CALENDAR = 'CLE',
  NETI = 'NET',
  DISABLED_FARES = 'DFR',
  CCLUB_TOGGLE = 'CLB',
  NEW_DOCUMENT_SECTION = 'FLG',
  DISABLED_WEB_CHECK_IN = 'DWC',
  ENABLED_WEB_CHECK_IN = 'WCB',
  EMOTIONAL_QUOTATION = 'EMO',
  NEW_ROOM_SELECTOR = 'ROO',
  NEW_ROOM_SELECTOR_TWO = 'RO2',
  CCLUB_2022 = 'CLB22',
  SHIP_AND_SHOREX = 'SS2',
  ADDITIONAL_INFO = 'ADD',
  DISABLE_DEPOSIT = 'DEPO',
  CCLUB_DEFAULT_NO = 'FLGCLB',
  PAX_NUM_DEFAULT = 'PNUM',
  PAX_AGE_DEFAULT = 'PAGE',
  TRANSPORTATION_INFO = 'TRINFO',
  TRANSPORTATION_SELECTION = 'TRSEL',
  NATIONALITY_SELECTION = 'NATSEL',
  MYBOOKING_SEARCH = 'MYB',
  LOGIN_CAROUSEL = 'CAR',
  AGENCY_DETAIL_EXTENDED = 'AGX',
  NEW_SUMMARY_PRINT = 'NSP',
  NEW_SUMMARY_PRINT_EDIT = 'NSPEDT',
  GROUP_PAYMENT = 'GRPP',
  GROUP_TOOL_SEARCH = 'GTS',
  SEA_DESTINATION = 'SEA',
  BOOKING_PARTIAL_PAYMENT = 'BPP',
  NEW_MYEXPLOR_LAYOUT = 'NMXL',
  NEW_WORLD_PAY_REDIRECT = 'NWP',
  DEPARTED_GROUP = 'DGRP',
  HIDE_GROUP_RATE = 'HDGRP',
  CALIFORNIA_LAW = 'CLW',
  UPSELL_FARE = 'FUP',
  UPSELL_SSV = "SSVAPP",
  LOGIN_CONFIRM_ALERT = 'LCA',
  FULLPAYMENTSRECEIVED_OVERRIDE = 'FPO',
  NEW_USER_PAGE = 'USR',
  CHECK_USER_EMAIL ='UQEML',
  PARTIAL_PAYMENT_NET ='PPN',
}
